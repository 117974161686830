import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import VueTheMask from 'vue-the-mask'
import VueSession from 'vue-session'

var options = {
  persist: true
}

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueSession, options)
Vue.use(VueTheMask)
Vue.use(VueRouter);
Vue.prototype.$toastr = toastr;

new Vue({
  render: h => h(App),
  el: '#app',
  router,
  components: { App }
}).$mount('#app')